import { RoleAssignmentGroup } from '@modules/auth/components/types/user';
import config from '@config/config';

import api from '../../../lib/api';
import { ResourceFetch } from '../../../meta/types/api';

const { GET } = api;

const ROLE_RESOURCE_BASE_PATH = `${config.identityServiceBasePath}/roles`;

export interface RoleResource {
  getRoleAssignmentGroup: ResourceFetch<RoleAssignmentGroup, { groupId: number }>;
}

const RoleResource: RoleResource = {
  getRoleAssignmentGroup: GET(`${ROLE_RESOURCE_BASE_PATH}/assignments/groups/:groupId`),
};

export default RoleResource;
