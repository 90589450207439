import produce, { Draft } from 'immer';
import { Claims, SentinelUser } from '@modules/auth/components/types/user';
import Auth from '@modules/auth/components/lib/auth';

import {
  ILogout,
  ISetConfigurableUser,
  ISetLoggedIn,
  ISetTokenChecked,
  ISetUserAlreadyHadTrial,
} from '../../actions/auth';
import * as actionTypes from '../../constants/actionTypes';

export const initialState = Object.freeze({
  isLoggedIn: false,
  tokenChecked: false,
  rootManaging: false,
  userAlreadyHadTrial: undefined as boolean | undefined,
});

export type AuthState<UserType = SentinelUser, ClaimsType = Claims> = typeof initialState & {
  authenticatedUser?: ClaimsType;
  configurableUser?: UserType;
};

type AuthAction =
  | ISetTokenChecked
  | ISetUserAlreadyHadTrial
  | ISetLoggedIn<SentinelUser, Claims>
  | ILogout
  | ISetConfigurableUser;

const auth = (state: AuthState = initialState, action: AuthAction) => {
  return produce<AuthState>(state, (draft: Draft<AuthState>) => {
    switch (action.type) {
      case actionTypes.SET_CONFIGURABLE_USER:
        draft.configurableUser = action.user;
        return;
      case actionTypes.LOGOUT:
        Auth.deleteAuthorization();
        draft.isLoggedIn = false;
        draft.configurableUser = undefined;
        draft.authenticatedUser = undefined;
        return;
      case actionTypes.SET_USER_ALREADY_HAD_TRIAL:
        draft.userAlreadyHadTrial = action.alreadyHadTrial;
        return;
      case actionTypes.SET_TOKEN_CHECKED:
        draft.tokenChecked = action.tokenChecked;
        return;
      case actionTypes.SET_LOGGED_IN:
        const { user } = action;
        draft.tokenChecked = true;
        draft.configurableUser = user;
        draft.isLoggedIn = true;
        draft.authenticatedUser = action.impersonatorClaims;
        draft.rootManaging = action.rootManaging;
        return;
      default:
        return;
    }
  });
};

export default auth;
