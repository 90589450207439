import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import { Switch } from 'react-router-dom';
import config from '@config/config';

import AsyncLoad from '@src/components/common/AsyncLoad';
import { WmsValidAccountRoute } from '@src/components/common/Route';
import { withTracker } from '@src/hocs';

import ConfigurationsContextProvider from './components/context';
import { Props, ConfigurationUtilityType, FunctionComponentProps } from './types';

export const RouteContainer = posed.div({
  enter: { opacity: 1, beforeChildren: true, delay: 125 },
  exit: { opacity: 0 },
});

const ConfigurationsList = AsyncLoad({
  loader: () => import('./components/Instances'),
}) as React.ComponentClass<any>;
const AsyncInstancesWithTracker = withTracker(ConfigurationsList);

const ConfigurationDetails = AsyncLoad({
  loader: () => import('./components/InstanceDetail'),
}) as React.ComponentClass<any>;
const AsyncInstanceDetailWithTracker = withTracker(ConfigurationDetails);

const NewConfiguration = AsyncLoad({
  loader: () => import('./components/NewInstance'),
}) as React.ComponentClass<any>;
const AsyncNewInstanceWithTracker = withTracker(NewConfiguration);

function ConfigurationUtilityBase({
  ConfigurationsList,
  NewConfiguration,
  ConfigurationDetails,
}: Props): ConfigurationUtilityType {
  return ({ location }: FunctionComponentProps) => {
    return (
      <PoseGroup className="configuration-routes-container">
        <RouteContainer key={location.pathname}>
          <ConfigurationsContextProvider>
            <Switch location={location}>
              {ConfigurationsList && (
                <WmsValidAccountRoute
                  exact
                  path={config.CONFIGURATIONS_UTILITY_PATH}
                  component={ConfigurationsList}
                />
              )}
              {NewConfiguration && (
                <WmsValidAccountRoute
                  exact
                  path={config.NEW_CONFIGURATION_PATH}
                  component={NewConfiguration}
                />
              )}
              {ConfigurationDetails && (
                <WmsValidAccountRoute
                  path={config.CONFIGURATION_PATH(':id')}
                  component={ConfigurationDetails}
                />
              )}
            </Switch>
          </ConfigurationsContextProvider>
        </RouteContainer>
      </PoseGroup>
    );
  };
}
export * from './types';
export { ConfigurationUtilityBase };
export default ConfigurationUtilityBase({
  ConfigurationsList: AsyncInstancesWithTracker,
  NewConfiguration: AsyncNewInstanceWithTracker,
  ConfigurationDetails: AsyncInstanceDetailWithTracker,
});
