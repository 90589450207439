import config from '@config/config';

import { Instance } from '../../meta/types/wms/Instance';
import { SidebarTab, ROOT_PATH } from '../../constants/paths/internal';

export const toDropdownItemOptions = (instances: Instance[], icon = 'settings') => {
  return instances
    .map(instance => ({
      value: instance.id,
      text: instance.name,
      key: instance.id,
      icon,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));
};

export const UUID_PLACEHOLDER = 'nnnnnnnn-nnnn-nnnn-nnnn-nnnnnnnnnnnn';

export const SECTION_DROPDOWN_VALUE = '_null_';

export const getSectionDropdownOption = (text: string, key: any) => {
  return {
    text,
    value: SECTION_DROPDOWN_VALUE,
    key,
  };
};

export const formatNumber = (x: number, roundedDigits?: number, fixed = false): string => {
  let result: string;
  if (roundedDigits !== undefined) {
    const roundedResult = Math.round(x * 10 ** roundedDigits) / 10 ** roundedDigits;
    if (fixed) {
      result = roundedResult.toFixed(roundedDigits);
    } else {
      result = roundedResult.toString();
    }
  } else {
    result = x.toString();
  }
  result = result.replace(/\B(?=(\d{3})+(?!\d))/g, '\u2009');
  // Remove whitespaces on the decimal part.
  const splitted = result.split('.');
  if (splitted.length > 1 && splitted[1].length > 3) {
    splitted[1] = splitted[1].replace(/\s+/, '');
    return splitted.join('.');
  }
  return result;
};

type FormatActiveTab = {
  [key in SidebarTab]: string;
};

export const formatActiveTab: FormatActiveTab = {
  [SidebarTab.ACCOUNT_SETTINGS]: 'Account Settings',
  [SidebarTab.DASHBOARD]: 'Dashboard',
  [SidebarTab.BILLING]: 'Billing',
  [SidebarTab.BYOC]: 'Collections',
  [SidebarTab.CONFIGURATOR]: 'Configuration Utility',
  [SidebarTab.STATISTICS]: 'Usage',
  [SidebarTab.HELP]: 'Help',
  [SidebarTab.NEWS]: "What's new",
  [SidebarTab.TPDI]: '3rd Party Data Import',
  [SidebarTab.BATCH]: 'Batch Processing Requests',
  [SidebarTab.STATAPI_BATCH]: 'Statistical Batch Requests',
  [SidebarTab.PLANS]: 'Plans',
};

export const activeTabToLink: FormatActiveTab = {
  [SidebarTab.ACCOUNT_SETTINGS]: config.USER_SETTINGS_PATH,
  [SidebarTab.DASHBOARD]: ROOT_PATH,
  [SidebarTab.BILLING]: config.ORDER_HISTORY,
  [SidebarTab.BYOC]: config.BYOC_PATH,
  [SidebarTab.CONFIGURATOR]: config.CONFIGURATIONS_UTILITY_PATH,
  [SidebarTab.STATISTICS]: config.USAGE_PATH,
  [SidebarTab.HELP]: config.HELP_PAGE_PATH,
  [SidebarTab.NEWS]: config.NEWS_PATH,
  [SidebarTab.STATAPI_BATCH]: config.STATAPI_BATCH_PATH,
  [SidebarTab.BATCH]: config.BATCH_PATH,
  [SidebarTab.TPDI]: config.TPDI_PATH,
  [SidebarTab.PLANS]: config.BILLING_PATH,
};

export const objectKeysToDropdownOptions = (o: Object) =>
  Object.keys(o).map(k => ({
    key: k,
    value: k,
    text: k,
  }));
