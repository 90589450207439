import Auth from '@src/config/default/modules/Authentication/components/lib/auth';

Auth.passwordChangeUrlClick = () => {
  window.open(
    'https://auth.fra1-1.cloudferro.com/auth/realms/code-de/login-actions/reset-credentials',
    '_blank',
  );
};

export default Auth;
