import { CommonServicesEndpoints } from '@src/config/baseAppConfig.types';
import { DefaultThemeSpecificServicesEndpoints } from '@src/config/default/config.types';

export const prodEndpoints: CommonServicesEndpoints & DefaultThemeSpecificServicesEndpoints = {
  wmsConfigurationServiceBasePath: 'https://code-de.sentinel-hub.com',
  identityServiceBasePath: 'https://code-de.sentinel-hub.com/ims',
  rateLimitSupervisorServiceBasePath: 'https://code-de.sentinel-hub.com',
  dashboardServiceBasePath: 'https://code-de.sentinel-hub.com',
  accountingServiceBasePath: 'https://code-de.sentinel-hub.com',
  ogcServiceBasePath: 'https://code-de.sentinel-hub.com',
  accessControlServiceBasePath: 'https://code-de.sentinel-hub.com',
  tpdiServiceBasePath: 'https://code-de.sentinel-hub.com',
  managementServiceBasePath: 'https://code-de.sentinel-hub.com',
  defaultWmsInstanceBaseUrl: 'https://code-de.sentinel-hub.com',

  byocGlobalServiceBasePath: 'https://code-de.sentinel-hub.com',
  byocEuCentralServiceBasePath: 'https://services.sentinel-hub.com',
  byocUsWestServiceBasePath: 'https://services-uswest2.sentinel-hub.com',
  byocCreodiasServiceBasePath: 'https://creodias.sentinel-hub.com',
  byocCodeDeServiceBasePath: 'https://code-de.sentinel-hub.com',
  usWestWmsServiceBasePath: 'https://services-uswest2.sentinel-hub.com',
  creodiasWmsServiceBasePath: 'https://creodias.sentinel-hub.com',
  byocSgsHqServiceBasePath: '',
};
