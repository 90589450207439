import { Datasource } from './Datasource';

export type Dataset = {
  '@id': string;
  id: DatasetId;
  name: string;
  sources: Datasource[];
};

export enum DatasetId {
  MODIS = 'MODIS',
  DEM = 'DEM',
  L8L1C = 'L8L1C',
  LOTL1 = 'LOTL1',
  LOTL2 = 'LOTL2',
  LTML1 = 'LTML1',
  LTML2 = 'LTML2',
  LETML1 = 'LETML1',
  LETML2 = 'LETML2',
  LMSSL1 = 'LMSSL1',
  S2L1C = 'S2L1C',
  S1GRD = 'S1GRD',
  S3OLCI = 'S3OLCI',
  S3SLSTR = 'S3SLSTR',
  S5PL2 = 'S5PL2',
  S2L2A = 'S2L2A',
  HLS = 'HLS',
  BYOC = 'CUSTOM',
}

export const supportsBackCoeff = (datasetId: DatasetId) => {
  return datasetId === DatasetId.S1GRD;
};

export const supportsAtmosphericCorrection = (datasetId: DatasetId) => {
  return datasetId === DatasetId.S2L1C;
};

export const supportsTemporal = (datasetId: DatasetId) => {
  return datasetId !== DatasetId.DEM;
};

export const supportsMosaicOrder = (datasetId: DatasetId) => {
  return datasetId !== DatasetId.DEM;
};

export const supportsTimeRange = (datasetId: DatasetId) => {
  return datasetId !== DatasetId.DEM;
};

export const supportsConstellation = (datasetId: DatasetId) => {
  return datasetId === DatasetId.HLS;
};

export const supportsCloudCoverage = (datasetId: DatasetId) => {
  return (
    datasetId === DatasetId.S2L1C ||
    datasetId === DatasetId.S2L2A ||
    datasetId === DatasetId.L8L1C ||
    datasetId === DatasetId.LOTL1 ||
    datasetId === DatasetId.LOTL2 ||
    datasetId === DatasetId.LTML1 ||
    datasetId === DatasetId.LTML2 ||
    datasetId === DatasetId.LETML1 ||
    datasetId === DatasetId.LETML2 ||
    datasetId === DatasetId.LMSSL1 ||
    datasetId === DatasetId.HLS ||
    datasetId === DatasetId.S3SLSTR
  );
};

export const supportsPreviewMode = (datasetId: DatasetId) => {
  return datasetId === DatasetId.S2L1C || datasetId === DatasetId.S2L2A;
};

export const supportsEGM = (datasetId: DatasetId) => {
  return datasetId === DatasetId.DEM;
};

export const supportsClampNegative = (datasetId: DatasetId) => {
  return datasetId === DatasetId.DEM;
};

export const supportsHarmonizeValues = (datasetId: DatasetId) =>
  datasetId === DatasetId.S2L1C || datasetId === DatasetId.S2L2A;

export enum Polarization {
  DV = 'DV',
  SH = 'SH',
  SV = 'SV',
  DH = 'DH',
}

export enum DemInstance {
  MAPZEN = 'MAPZEN',
  COPERNICUS = 'COPERNICUS',
  COPERNICUS_30 = 'COPERNICUS_30',
  COPERNICUS_90 = 'COPERNICUS_90',
  DEFAULT = 'DEFAULT',
}

export enum OrbitDirection {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
  ANY = 'ANY',
}

export enum AcquisitionMode {
  ANY = 'ANY',
  IW = 'IW',
  EW = 'EW',
  SM = 'SM',
}

export enum Resolution {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  ANY = 'ANY',
}

export interface SpeckleFilterNone {
  type: 'NONE';
  text: 'None';
}

export interface SpeckleFilterLee {
  type: 'LEE';
  windowSizeX: number;
  windowSizeY: number;
  text: 'Lee';
}

export type SpeckleFilter = SpeckleFilterNone | SpeckleFilterLee;

export enum S3SlstrView {
  NADIR = 'NADIR',
  OBLIQUE = 'OBLIQUE',
}

export const S1_RESOLUTION_OPTIONS = [
  { value: Resolution.ANY, text: 'Any', key: Resolution.ANY },
  { value: Resolution.HIGH, text: 'High', key: Resolution.HIGH },
  { value: Resolution.MEDIUM, text: 'Medium', key: Resolution.MEDIUM },
];

export const ORBIT_DIRECTION_OPTIONS = [
  { value: OrbitDirection.ANY, text: 'Any', key: OrbitDirection.ANY },
  { value: OrbitDirection.ASCENDING, text: 'Ascending', key: OrbitDirection.ASCENDING },
  { value: OrbitDirection.DESCENDING, text: 'Descending', key: OrbitDirection.DESCENDING },
];

export const S1_POLARIZATION_OPTIONS = [
  { value: Polarization.DV, text: 'VV + VH', key: Polarization.DV },
  { value: Polarization.SH, text: 'HH', key: Polarization.SH },
  { value: Polarization.SV, text: 'VV', key: Polarization.SV },
  { value: Polarization.DH, text: 'HH + HV', key: Polarization.DH },
];

export const DEM_INSTANCE_OPTIONS = [
  { value: DemInstance.MAPZEN, text: 'Mapzen DEM', key: 'mapzen' },
  { value: DemInstance.COPERNICUS_30, text: 'Copernicus DEM 30/90m', key: 'copernicus_30' },
  { value: DemInstance.COPERNICUS_90, text: 'Copernicus DEM 90m', key: 'copernicus_90' },
  { value: DemInstance.DEFAULT, text: 'Default', key: 'default' },
];

export const ORTHORECTIFY_OPTIONS = [
  { value: false, text: 'Disabled', key: 'disabled' },
  { value: DemInstance.MAPZEN, text: 'Yes - using Mapzen DEM', key: 'mapzen' },
  { value: DemInstance.COPERNICUS, text: 'Yes - using Copernicus 10/30m DEM', key: 'copernicus' },
  {
    value: DemInstance.COPERNICUS_30,
    text: 'Yes - using Copernicus 30m DEM',
    key: 'copernicus_30',
  },
  {
    value: DemInstance.COPERNICUS_90,
    text: 'Yes - using Copernicus 90m DEM',
    key: 'copernicus_90',
  },
  {
    value: DemInstance.DEFAULT,
    text: 'Yes - using default DEM',
    key: 'default',
  },
];

export const BACK_COEFF_OPTIONS = [
  { value: 'BETA0', text: 'beta0', key: 'beta0' },
  { value: 'SIGMA0_ELLIPSOID', text: 'sigma0', key: 'sigma0' },
  { value: 'GAMMA0_ELLIPSOID', text: 'gamma0', key: 'gamma0' },
  { value: 'GAMMA0_TERRAIN', text: 'gamma0 (radiometric terrain corrected)', key: 'gamma0_rtc' },
];

export const SPECKLE_FILTER_NONE_DEFAULT: SpeckleFilterNone = {
  type: 'NONE',
  text: 'None',
};

export const SPECKLE_FILTER_LEE_DEFAULT: SpeckleFilterLee = {
  type: 'LEE',
  text: 'Lee',
  windowSizeX: 3,
  windowSizeY: 3,
};

export const SPECKLE_FILTER_OPTIONS: SpeckleFilter[] = [
  SPECKLE_FILTER_NONE_DEFAULT,
  SPECKLE_FILTER_LEE_DEFAULT,
];

export const S3SLSTR_VIEW_OPTIONS = [
  { value: S3SlstrView.NADIR, text: 'Nadir', key: S3SlstrView.NADIR },
  { value: S3SlstrView.OBLIQUE, text: 'Oblique', key: S3SlstrView.OBLIQUE },
];

export enum HlsConstellation {
  SENTINEL = 'SENTINEL',
  LANDSAT = 'LANDSAT',
  ANY = 'ANY',
}

export const HLS_CONSTELLATION_OPTIONS = [
  { value: HlsConstellation.LANDSAT, text: 'Landsat', key: HlsConstellation.LANDSAT },
  { value: HlsConstellation.ANY, text: 'Any', key: HlsConstellation.ANY },
  { value: HlsConstellation.SENTINEL, text: 'Sentinel', key: HlsConstellation.SENTINEL },
];
export enum LandsatFilters {
  Tier1 = 'TIER_1',
  All = 'ALL_TIERS',
  Tier1RT = 'TIER_1_AND_RT',
}

export const supportsLandsatFiltering = (datasetId: DatasetId) =>
  datasetId === DatasetId.L8L1C ||
  datasetId === DatasetId.LOTL1 ||
  datasetId === DatasetId.LOTL2 ||
  datasetId === DatasetId.LTML1 ||
  datasetId === DatasetId.LTML2 ||
  datasetId === DatasetId.LETML1 ||
  datasetId === DatasetId.LETML2 ||
  datasetId === DatasetId.LMSSL1;

export const supportsActiveLandsatFiltering = (datasetId: DatasetId) =>
  datasetId === DatasetId.LOTL1 ||
  datasetId === DatasetId.LOTL2 ||
  datasetId === DatasetId.LETML1 ||
  datasetId === DatasetId.LETML2;

export const LANDSAT_BASE_FILTERS_OPTIONS = [
  { value: LandsatFilters.Tier1, text: 'Tier 1', key: LandsatFilters.Tier1 },
  { value: LandsatFilters.All, text: 'All Tiers', key: LandsatFilters.All },
];

export const LANDSAT_ACTIVE_FILTERS_OPTIONS = LANDSAT_BASE_FILTERS_OPTIONS.concat({
  value: LandsatFilters.Tier1RT,
  text: 'Tier 1 and RT',
  key: LandsatFilters.Tier1RT,
});

type DatasetToDocsLink = {
  [key in DatasetId]: string | undefined;
};

export const DATASET_TO_DOCS_LINK: DatasetToDocsLink = {
  [DatasetId.BYOC]: 'https://docs.sentinel-hub.com/api/latest/data/byoc/',
  [DatasetId.DEM]: 'https://docs.sentinel-hub.com/api/latest/data/dem/',
  [DatasetId.L8L1C]: 'https://docs.sentinel-hub.com/api/latest/data/landsat-8/',
  [DatasetId.LTML1]: 'https://docs.sentinel-hub.com/api/latest/data/landsat-tm/',
  [DatasetId.LTML2]: 'https://docs.sentinel-hub.com/api/latest/data/landsat-tm-l2/',
  [DatasetId.LETML1]: 'https://docs.sentinel-hub.com/api/latest/data/landsat-etm/',
  [DatasetId.LETML2]: 'https://docs.sentinel-hub.com/api/latest/data/landsat-etm-l2/',
  [DatasetId.LMSSL1]: 'https://docs.sentinel-hub.com/api/latest/data/landsat-mss/',
  [DatasetId.LOTL1]: 'https://docs.sentinel-hub.com/api/latest/data/landsat-8-l2/',
  [DatasetId.LOTL2]: 'https://docs.sentinel-hub.com/api/latest/data/landsat-8-l2/',
  [DatasetId.MODIS]: 'https://docs.sentinel-hub.com/api/latest/data/modis/mcd/',
  [DatasetId.S1GRD]: 'https://docs.sentinel-hub.com/api/latest/data/sentinel-1-grd/',
  [DatasetId.S2L1C]: 'https://docs.sentinel-hub.com/api/latest/data/sentinel-2-l1c/',
  [DatasetId.S2L2A]: 'https://docs.sentinel-hub.com/api/latest/data/sentinel-2-l2a/',
  [DatasetId.S3OLCI]: 'https://docs.sentinel-hub.com/api/latest/data/sentinel-3-olci-l1b/',
  [DatasetId.S3SLSTR]: 'https://docs.sentinel-hub.com/api/latest/data/sentinel-3-slstr-l1b/',
  [DatasetId.S5PL2]: 'https://docs.sentinel-hub.com/api/latest/data/sentinel-5p-l2/',
  [DatasetId.HLS]: 'https://docs.sentinel-hub.com/api/latest/data/hls/',
};
export const BYOC_SUBTYPE_OPTIONS = [
  { value: 'BATCH', text: 'Batch', key: 'BATCH' },
  { value: 'BYOC', text: 'BYOC', key: 'BYOC' },
  { value: 'ZARR', text: 'Zarr', key: 'ZARR' },
];
