import baseConfig from '../baseConfig';
import AppConfig, { CommonServicesConfig, KeycloakConfig } from '../../baseAppConfig.types';
import { Environment } from '../../baseAppConfig';
import { prodEndpoints } from './common/prodEndpoints';
import defaultClaimsKeys from './common/defaultClaimsKeys';

const prodServicesConfig: CommonServicesConfig & KeycloakConfig = {
  clientId: '51697680-472b-11ee-be56-0242ac120002',
  appsBaseUrl: 'https://codedeapps.sentinel-hub.com/',
  configurationTemplateAccountId: '7d63876e-8f02-4a0c-bcf6-0a1e613e70b4',

  sentry: {
    dsn: 'https://5336c02b48194ca09ac6f65803f0d663@sentry.io/1339645',
    env: 'prod',
    enable: true,
  },

  analytics: {
    trackingID: 'DQMEKKLA',
    enable: true,
  },

  keycloakAuthUrl: 'https://code-de.sentinel-hub.com/auth',
  keycloakRealm: 'main',
  ...defaultClaimsKeys,

  environment: Environment.PRODUCTION,
};

const prodConfig: AppConfig = {
  ...baseConfig,
  ...prodServicesConfig,
  ...prodEndpoints,
};

export default prodConfig;
