import React, { createContext } from 'react';
import { connect } from 'react-redux';

import { StoreState } from '@src/meta/types/store';
import { RoleAssignmentGroup, Role } from '@modules/auth/components/types/user';
import { getRoleAssignmentGroup } from '@modules/auth/components/lib/account';

type CollectionsContextProps = {
  readonly: boolean;
};

export const CollectionsContext = createContext<CollectionsContextProps>({
  readonly: false,
});

type Props = {
  children: React.ReactNode;
};

type PropsFromStore = {
  roleAssignments: RoleAssignmentGroup | undefined;
};

function hasReadonlyAccess(roleAssignmentGroup: RoleAssignmentGroup | undefined) {
  return Boolean(
    roleAssignmentGroup && roleAssignmentGroup.assignments.byc === Role.APPLICATION_RO,
  );
}

function CollectionsContextProvider({ children, roleAssignments }: Props & PropsFromStore) {
  return (
    <CollectionsContext.Provider
      value={{
        readonly: hasReadonlyAccess(roleAssignments),
      }}
    >
      {children}
    </CollectionsContext.Provider>
  );
}

const mapStateToProps = (state: StoreState): PropsFromStore => {
  return {
    roleAssignments: getRoleAssignmentGroup(state),
  };
};

export default connect(mapStateToProps)(CollectionsContextProvider);
