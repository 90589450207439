import React, { createContext } from 'react';
import { connect } from 'react-redux';

import { StoreState } from '@src/meta/types/store';
import { RoleAssignmentGroup, Role } from '@modules/auth/components/types/user';
import { getRoleAssignmentGroup } from '@modules/auth/components/lib/account';
import { isMaintenanceInProgress } from '@src/utils/maintenance';
import { RoleAssignment } from '../types/user';

export type AuthenticationContextProps = {
  readonly: boolean;
  maintenanceInProgress: boolean;
};

export const AuthenticationContext = createContext<AuthenticationContextProps>({
  readonly: false,
  maintenanceInProgress: false,
});

type Props = {
  children: React.ReactNode;
};

type PropsFromStore = {
  roleAssignments: RoleAssignmentGroup | undefined;
};

function hasReadonlyAccess(roleAssignmentGroup: RoleAssignmentGroup | undefined) {
  return Boolean(
    (roleAssignmentGroup && roleAssignmentGroup.assignments.idn === Role.APPLICATION_RO) ||
      isMaintenanceInProgress(RoleAssignment.IDENTITY),
  );
}

function AuthenticationContextProvider({ children, roleAssignments }: Props & PropsFromStore) {
  return (
    <AuthenticationContext.Provider
      value={{
        readonly: hasReadonlyAccess(roleAssignments),
        maintenanceInProgress: isMaintenanceInProgress(RoleAssignment.IDENTITY),
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

const mapStateToProps = (state: StoreState): PropsFromStore => {
  return {
    roleAssignments: getRoleAssignmentGroup(state),
  };
};

export default connect(mapStateToProps)(AuthenticationContextProvider);
