import { SentinelUser, ServerSentinelUser } from '@modules/auth/components/types/user';
import config from '@config/config';

import api from '../../../lib/api';
import { ResourceFetch } from '../../../meta/types/api';

const { GET, PUT, DELETE } = api;

const USER_RESOURCE_BASE_PATH = `${config.identityServiceBasePath}/users`;

type UserIdentifiable = {
  userId: string;
};

export interface UserResource {
  get: ResourceFetch<ServerSentinelUser, UserIdentifiable>;
  update: ResourceFetch<any, SentinelUser>;
  delete: ResourceFetch<any, UserIdentifiable>;
  checkTrialAvailability: ResourceFetch<boolean, { email?: string; userId: string }>;
  isUserCompliant: ResourceFetch<boolean | null, { userId: string; trigger: string }>;
}

const UserResource: UserResource = {
  get: GET(`${USER_RESOURCE_BASE_PATH}/:userId`),
  update: PUT(`${USER_RESOURCE_BASE_PATH}/:userId`),
  delete: DELETE(`${USER_RESOURCE_BASE_PATH}/:userId`),
  checkTrialAvailability: GET(`${USER_RESOURCE_BASE_PATH}/trial`),
  isUserCompliant: GET(`${USER_RESOURCE_BASE_PATH}/:userId/compliance?trigger=:trigger`),
};

export default UserResource;
