import { Location } from 'history';
import React from 'react';
import { connect } from 'react-redux';

import { Claims, SentinelUser, DomainAccountWithDomain } from '@modules/auth/components/types/user';
import { getUsedAccount } from '@modules/auth/components/lib/account';

import { ILogout, logout } from '../../../actions/auth';
import { SidebarTab } from '../../../constants/paths/internal';
import { ThunkDispatch } from '../../../meta/types/actions';
import { StoreState } from '../../../meta/types/store';
import Navbar from './Navbar';

type PropsFromState = {
  authenticatedUser?: Claims;
  activeTab: SidebarTab;
  configurableUser?: SentinelUser;
  sidebarCollapsed: boolean;
  rootManaging: boolean;
  account: DomainAccountWithDomain | undefined;
};

type PropsFromDispatch = {
  logout: () => Promise<ILogout>;
};

type NavbarContextProps = PropsFromState &
  PropsFromDispatch & {
    location: Location;
  };

export const NavbarContext = React.createContext<NavbarContextProps>({
  activeTab: SidebarTab.DASHBOARD,
  authenticatedUser: undefined,
  configurableUser: undefined,
  logout: undefined as any,
  sidebarCollapsed: false,
  rootManaging: false,
  location: undefined as any,
  account: undefined,
});

const NavbarContainer = ({
  authenticatedUser,
  configurableUser,
  logout,
  activeTab,
  sidebarCollapsed,
  rootManaging,
  location,
  account,
}: NavbarContextProps) => {
  return (
    <NavbarContext.Provider
      value={{
        location,
        activeTab,
        authenticatedUser,
        configurableUser,
        logout,
        sidebarCollapsed,
        rootManaging,
        account,
      }}
    >
      <Navbar
        sidebarCollapsed={sidebarCollapsed}
        rootManaging={rootManaging}
        account={account}
        activeTab={activeTab}
      />
    </NavbarContext.Provider>
  );
};

function mapStateToProps(state: StoreState): PropsFromState {
  return {
    authenticatedUser: state.auth.authenticatedUser,
    activeTab: state.ui.activeTab,
    configurableUser: state.auth.configurableUser,
    sidebarCollapsed: state.ui.sidebarCollapsed,
    rootManaging: state.auth.rootManaging,
    account: getUsedAccount(state.account.accounts, state.auth.authenticatedUser),
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatch): PropsFromDispatch => {
  return {
    logout: () => dispatch(logout()),
  };
};

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(NavbarContainer);
