import { DomainAccountWithDomain } from '@modules/auth/components/types/user';
import { RoleAssignmentGroup } from '@modules/auth/components/types/user/index';

import * as actionTypes from '../../constants/actionTypes';

export interface ISetAccounts {
  type: actionTypes.SET_ACCOUNTS;
  accounts: DomainAccountWithDomain[];
}

export const setAccounts = (accounts: DomainAccountWithDomain[]): ISetAccounts => ({
  type: actionTypes.SET_ACCOUNTS,
  accounts,
});

export interface ISetAccountsFetching {
  type: actionTypes.SET_ACCOUNTS_FETCHING;
  fetching: boolean;
}

export const setAccountsFetching = (fetching: boolean): ISetAccountsFetching => ({
  type: actionTypes.SET_ACCOUNTS_FETCHING,
  fetching,
});

export interface ISetAssignmentGroups {
  type: actionTypes.SET_ROLE_ASSIGNMENT_GROUPS;
  rags: RoleAssignmentGroup[];
}

export const setAssignmentGroups = (rags: RoleAssignmentGroup[]): ISetAssignmentGroups => ({
  type: actionTypes.SET_ROLE_ASSIGNMENT_GROUPS,
  rags,
});
