import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import { Switch, Route, Redirect } from 'react-router-dom';
import config from '@config/config';

import AsyncLoad from '@src/components/common/AsyncLoad';
import { WmsValidAccountRoute } from '@src/components/common/Route';
import { withTracker } from '@src/hocs';

import { Props, CollectionsType, FunctionComponentProps } from './types';
import CollectionsContextProvider from './components/context';

export const RouteContainer = posed.div({
  enter: { opacity: 1, beforeChildren: true, delay: 125 },
  exit: { opacity: 0 },
});

const AsyncByocPage = AsyncLoad({
  loader: () => import('./components/Page'),
}) as React.ComponentClass<any>;
const AsyncByocPageWithTracker = withTracker(AsyncByocPage);

function CollectionsBase({}: Props): CollectionsType {
  return ({ location }: FunctionComponentProps) => {
    return (
      <PoseGroup className="configuration-routes-container">
        <RouteContainer key={location.pathname}>
          <CollectionsContextProvider>
            <Switch location={location}>
              <WmsValidAccountRoute
                path={config.BYOC_PATH_FILTER(':filter')}
                component={AsyncByocPageWithTracker}
              />
              <Route exact path={config.BYOC_OLD_PATH}>
                <Redirect to={config.BYOC_PATH} />
              </Route>
              <Route
                exact
                path={config.BYOC_OLD_COLLECTIONS_DETAILS_PATH(':id')}
                render={props => (
                  <Redirect to={config.BYOC_COLLECTION_DETAILS_PATH(props.match.params.id)} />
                )}
              />
              <Route exact path={config.BYOC_OLD_NEW_COLLECTION_PATH}>
                <Redirect to={config.BYOC_NEW_COLLECTION_PATH} />
              </Route>
            </Switch>
          </CollectionsContextProvider>
        </RouteContainer>
      </PoseGroup>
    );
  };
}
export * from './types';
export { CollectionsBase };
export default CollectionsBase({});
