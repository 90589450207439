import config from '@config/config';

import AccountResource from '@src/services/Identity/AccountResource';
import { StoreState } from '@src/meta/types/store';

import {
  DomainAccountWithDomain,
  Claims,
  RoleAssignmentGroup,
  SentinelUser,
} from '@modules/auth/components/types/user';
import { mapAccount } from '../../types/account';

export const findMostRecentAccounts = (
  accounts: DomainAccountWithDomain[],
): DomainAccountWithDomain[] => {
  return [];
};

export const getUsedAccountId = (
  accounts: DomainAccountWithDomain[],
  claims: Claims | undefined,
): string => {
  const account = getUsedAccount(accounts, claims);
  return account ? account.id : '';
};

export const getUsedAccount = (accounts: DomainAccountWithDomain[], claims: Claims | undefined) => {
  return accounts.find(acc => claims && acc.id === claims[config.accountClaimsKey]);
};

export const getAccounts = async (userId: string): Promise<DomainAccountWithDomain[]> => {
  try {
    const resp = await AccountResource.list({ userId });
    return resp.member.map(mapAccount) as DomainAccountWithDomain[];
  } catch (err) {
    return [];
  }
};

export function getRoleAssignmentGroup(
  state: StoreState<SentinelUser, Claims>,
): RoleAssignmentGroup | undefined {
  const usedAccountId = getUsedAccountId(state.account.accounts, state.auth.authenticatedUser);
  const shAccount = state.account.accounts.find(acc => acc.id === usedAccountId);
  return state.account.rags.find(r => r.id === shAccount?.data.rolesAssignment.rag);
}
