import produce, { Draft } from 'immer';

import { ISetSidebarCollapsed, ISetTab, ISetBreadcrumb, IAppendBreadcrumb } from '../../actions/ui';
import * as actionTypes from '../../constants/actionTypes';
import { SidebarTab } from '../../constants/paths/internal';
import LocalStorage from '../../lib/storage';
import { NestedBreadcrumb } from '../../meta/types/ui';

export const UI_STORAGE_KEY_ITEM = 'ui';

type UiSavedState = {
  sidebarCollapsed?: boolean;
};

const uiSavedState = (LocalStorage.getStorageKeyItem(UI_STORAGE_KEY_ITEM) ?? {}) as UiSavedState;

export const initialState = Object.freeze({
  activeTab: SidebarTab.DASHBOARD,
  sidebarCollapsed: uiSavedState.sidebarCollapsed ?? false,
  dashboardEditable: true,
  nestedBreadcrumb: [] as NestedBreadcrumb[],
});

export type UiState = typeof initialState;

/* When sidebar is collapsed we need to delay the resize event for animation to finish for dashboard grid layout to resize accordingly */
const RESIZE_EVENT_DELAY = 275;

const ui = (
  state: UiState = initialState,
  action: ISetTab | ISetSidebarCollapsed | ISetBreadcrumb | IAppendBreadcrumb,
) => {
  return produce<UiState>(state, (draft: Draft<UiState>) => {
    switch (action.type) {
      case actionTypes.SET_TAB:
        draft.activeTab = action.menuTab;
        return;

      case actionTypes.SET_SIDEBAR_COLLAPSED:
        draft.sidebarCollapsed = action.collapsed;
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, RESIZE_EVENT_DELAY);
        uiSavedState.sidebarCollapsed = action.collapsed;
        LocalStorage.setStorageItem(UI_STORAGE_KEY_ITEM, uiSavedState);
        return;
      case actionTypes.SET_BREADCRUMB:
        draft.nestedBreadcrumb = action.breadcrumb;
        return;
      case actionTypes.APPEND_BREADCRUMB:
        draft.nestedBreadcrumb.push(action.breadcrumb);
        return;
      default:
        return;
    }
  });
};

export default ui;
