import CODEDE_LOGO from '@src/images/logo_codede.png';
import LOGO_COLLAPSED from '@src/images/logo_collapsed.png';

import { CommonStylesConfig } from '../baseAppConfig.types';

const stylesConfig: CommonStylesConfig = {
  primaryTextColor: '#FDFEFF',
  secondaryTextColor: '#F8A740',
  terciaryTextColor: '#1D2846',
  quaternaryTextColor: '#FDFEFF',

  backgroundColor: '#1D2846',
  lightBackgroundColor: '#BAD3DC',
  darkBackgroundColor: '#1E2E51',

  backgroundGrey: '#efefef',
  lightBackgroundGrey: '#fafafa',
  darkBackgroundGrey: '#cdcdd0',

  primaryColor: '#FAA73F',
  secondaryColor: '#BAD3DC',
  terciaryColor: '#F27128',

  sidebarColor: '#BAD3DC',
  selectedSidebarItemBackground: '#1D2846',

  iconColor: 'red',

  logo: CODEDE_LOGO,
  collapsedLogo: LOGO_COLLAPSED,

  toastSuccessColor: 'hsl(185, 81%, 29%)',
  toastErrorColor: 'hsl(360, 64%, 55%)',
};
export default stylesConfig;
