import { RoleAssignmentGroup, RoleAssignment, Role } from '@modules/auth/components/types/user';

const hasAccess = (rag: RoleAssignment, ra?: RoleAssignmentGroup) =>
  ra && ra.assignments[rag] && ra.assignments[rag] !== Role.NO_ROLE;

export const hasAccessToByoc = (ra?: RoleAssignmentGroup) => hasAccess(RoleAssignment.BYOC, ra);

export const hasAccessToBatch = (ra?: RoleAssignmentGroup) => hasAccess(RoleAssignment.BATCH, ra);

export const hasAccessToBatchStatistical = (ra?: RoleAssignmentGroup) =>
  hasAccess(RoleAssignment.BATCH_STATISTICS, ra);

export const hasAccessToTPDI = (ra?: RoleAssignmentGroup) =>
  hasAccess(RoleAssignment.DATA_IMPORT, ra);
