import jwt_dec from 'jwt-decode';
import * as React from 'react';
import { connect } from 'react-redux';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import { setLoggedIn } from '@src/actions/auth';
import UserResource from '@src/services/Identity/UserResource';
import RoleResource from '@src/services/Identity/RoleResource';
import { setAccounts, setAssignmentGroups } from '@src/actions/accounts';
import { alertError, alertSuccess } from '@src/lib/alert';
import config from '@config/config';

import {
  Claims,
  SentinelUser,
  toInternalUser,
  DomainAccountWithDomain,
  RoleAssignmentGroup,
} from './components/types/user';
import { keycloak } from './components/lib';
import { AuthorizationProps as AuthorizationProps_ } from './types';
import FullPageLoader from './components/FullPageLoader';
import { getAccounts } from './components/lib/account';
import AuthenticationContextProvider from './components/context';

type State = {
  authorizationFail: boolean;
};

type FullAuthorizationProps = AuthorizationProps_ & State;

const getRoleAssignments = async (
  accounts: DomainAccountWithDomain[],
): Promise<RoleAssignmentGroup[]> => {
  const rags = await Promise.all(
    accounts.map(acc =>
      acc.data.rolesAssignment
        ? RoleResource.getRoleAssignmentGroup({ groupId: acc.data.rolesAssignment.rag }).catch(
            e => null,
          )
        : null,
    ),
  );
  return rags.filter(rag => rag !== null) as RoleAssignmentGroup[];
};

const withAuthorization = (WrappedComponent: React.ComponentClass<FullAuthorizationProps>) => {
  const Authorized = class extends React.Component<FullAuthorizationProps> {
    state: State = { authorizationFail: false };

    getImpersonationParams() {
      const search = window.location.hash.replace(/^[^?]+\?/, '');
      const queryParams = new URLSearchParams(search);
      const impersonatedAccountId = queryParams.get('impersonating_account');
      const impersonatedUserId = queryParams.get('impersonating_user');
      return { impersonatedUserId, impersonatedAccountId };
    }

    isImpersonating = (
      impersonatedUserId: string | null,
      impersonatedAccountId: string | null,
    ): boolean => {
      if (impersonatedUserId !== null && impersonatedAccountId !== null) {
        return true;
      }
      if (impersonatedUserId !== null || impersonatedAccountId !== null) {
        alertError(
          'Both impersonated user ID and impersonated account ID must be set for impersonation!',
        );
      }
      return false;
    };

    updateClaimsForImpersonation = (
      claims: Claims,
      impersonatedUserId: string,
      impersonatedAccountId: string,
    ) => {
      claims[config.userClaimsKey] = impersonatedUserId;
      claims[config.accountClaimsKey] = impersonatedAccountId;
    };

    login(user: SentinelUser, claims: Claims, impersonating: boolean) {
      const { setLoggedIn } = this.props;
      setLoggedIn(user, claims, impersonating);
      return true;
    }

    render() {
      return (
        <ReactKeycloakProvider
          LoadingComponent={<FullPageLoader />}
          initOptions={{
            pkceMethod: 'S256',
            flow: 'standard',
            checkLoginIframe: true,
            onLoad: 'login-required',
            enableLogging: true,
          }}
          authClient={keycloak}
          onEvent={async ev => {
            if (ev === 'onAuthError' || ev === 'onAuthRefreshError') {
              console.log('ERROR fail', ev);
              this.setState({ authorizationFail: true });
            } else if (
              ev === 'onAuthRefreshSuccess' ||
              ev === 'onAuthSuccess' ||
              ev === 'onReady'
            ) {
              const claims: Claims = jwt_dec(keycloak.token!);
              try {
                let userId: string = claims.sub;
                const { impersonatedUserId, impersonatedAccountId } = this.getImpersonationParams();
                const isImpersonating = this.isImpersonating(
                  impersonatedUserId,
                  impersonatedAccountId,
                );
                if (isImpersonating) {
                  userId = impersonatedUserId!;
                }
                const user = toInternalUser(await UserResource.get({ userId }));
                let accounts = await getAccounts(userId);
                if (isImpersonating) {
                  accounts = accounts.filter(a => a.id === impersonatedAccountId);
                }
                const rags = await getRoleAssignments(accounts);
                this.props.setAssignmentGroups(rags);
                this.props.setAccounts(accounts);
                if (isImpersonating) {
                  this.updateClaimsForImpersonation(
                    claims,
                    impersonatedUserId!,
                    impersonatedAccountId!,
                  );
                }
                this.login(user, claims, isImpersonating);
                if (isImpersonating) {
                  alertSuccess(`Managing: ${impersonatedAccountId}`);
                }
              } catch (err) {
                console.log('ERROR', err);
                this.setState({ authorizationFail: true });
              }
            }
          }}
        >
          <AuthenticationContextProvider>
            <WrappedComponent {...this.props} {...this.state} />
          </AuthenticationContextProvider>
        </ReactKeycloakProvider>
      );
    }
  };

  return connect(null, mapDispatchToProps)(Authorized as any);
};

const mapDispatchToProps = {
  setLoggedIn,
  setAccounts,
  setAssignmentGroups,
};

export default withAuthorization;
export type AuthorizationProps = FullAuthorizationProps;
