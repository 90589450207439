import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import BillingStyle from '@modules/billing/components/BillingPage/style';

import TargetBlankLink from '@src/components/common/TargetBlankLink';
import { setBreadcrumb, setMenuTab } from '@src/actions/ui';
import { NestedBreadcrumb } from '@src/meta/types/ui';
import InfoMessage from '@src/components/common/banners/InfoMessage';
import { SidebarTab } from '@src/constants/paths/internal';

interface Props {
  children: React.ReactNode;
}
type PropsFromDispatch = {
  setMenuTab: (menuTab: SidebarTab) => void;
  setBreadcrumb: (breadcrumb: NestedBreadcrumb[]) => void;
};

const ExternalAuthBillingPage = ({
  children,
  setBreadcrumb,
  setMenuTab,
}: Props & PropsFromDispatch) => {
  useEffect(() => {
    setBreadcrumb([]);
    setMenuTab(SidebarTab.PLANS);
  }, [setBreadcrumb]);
  return (
    <ExtBillingStyles>
      <BillingStyle className="billing">
        <div className="billing__content">{children}</div>
      </BillingStyle>
    </ExtBillingStyles>
  );
};

const ConnectedExternalAuthBillingPage = connect(undefined, {
  setBreadcrumb,
  setMenuTab: (tab: SidebarTab) => setMenuTab(tab),
})(ExternalAuthBillingPage);

const BillingCODEDE = () => {
  return (
    <ConnectedExternalAuthBillingPage>
      <InfoMessage
        className="ext-billing-message"
        size="large"
        content={
          <>
            Please contact{' '}
            <TargetBlankLink href="https://code-de.org/helpdesk">
              <u>CODE-DE Support</u>
            </TargetBlankLink>{' '}
            to apply for additional quotas on CODE-DE DataCube.
          </>
        }
      />
    </ConnectedExternalAuthBillingPage>
  );
};
export default BillingCODEDE;

const ExtBillingStyles = styled.div`
  h2 {
    margin-bottom: 0;
  }
  .ext-billing-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ext-billing-message {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;
