import { isAfter } from 'date-fns';

import { DomainAccount, DomainAccountWithDomain, ServerDomainAccount } from '../user';

export enum Account {
  FREE_ACCOUNT = 1,
  EO_BROWSER = 10000,
  ROOT = 20000,
  SH_SERVICE = 21000,
  TRIAL = 11000,
  EXPLORATION = 12000,
  BASIC = 13000,
  ENTERPRISE = 14000,
  ENTERPRISE_S = 14001,
  ENTERPRISE_L = 14002,
}

export enum Domain {
  SH = 1,
  EO_BROWSER = 2,
  CREODIAS = 5,
  CODEDE = 6,
}

export function mapAccount(account: ServerDomainAccount): DomainAccount {
  return {
    ...account,
    created: new Date(account.created),
    validTo: new Date(account.validTo),
  };
}

export function isAccountValid(account?: DomainAccount | DomainAccountWithDomain): boolean {
  if (!account) {
    return false;
  }
  return isAfter(account.validTo, new Date());
}

export function isFreeAccount(type: Account) {
  return type === Account.FREE_ACCOUNT;
}
