import { Location } from 'history';
import { parse, stringify } from 'querystring';

import { Instance } from '../../meta/types/wms/Instance';

export function injectParameters(
  urlTemplate: string,
  data: any,
  hasBody = true,
): { url: string; params: string[] } {
  let url = urlTemplate;
  const params: string[] = [];

  data = { ...data };

  for (const tag of url.match(/:\w+/g) || []) {
    const urlTag = tag.slice(1);

    // PORT :8000/...
    if (!isNaN(parseInt(urlTag, 10))) {
      delete data[urlTag];
      continue;
    }
    let value = data[urlTag];
    if (value === undefined) {
      value = urlTag;
    }
    url = url.replace(tag, encodeURIComponent(value));
    params.push(urlTag);
    delete data[urlTag];
  }

  if (!hasBody) {
    const qs = stringify(data);
    if (qs) {
      url += (url.indexOf('?') >= 0 ? '&' : '?') + qs;
    }
  }
  return { url, params };
}

export const openExternalUrl = (path: string) => () => {
  window.open(path, '_blank');
};

export function parseQueryParams(location: Location | typeof window.location) {
  return parse(location.search.replace('?', ''));
}

export const generateEobrowserLink = (instance: Instance): string =>
  `https://apps.sentinel-hub.com/eo-browser/?zoom=10&lat=41.9&lng=12.5&themeId=${instance.id}`;
