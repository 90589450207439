import { RoleAssignmentGroup } from '@modules/auth/components/types/user';

import { Account } from '../../types/account';

const TRIAL_ACCOUNT_TEXT = 'Trial Account';

const accountTypeToTextMap = new Map([
  [Account.EO_BROWSER, 'EO Browser only'],
  [Account.ROOT, 'Root Account'],
  [Account.SH_SERVICE, 'Sentinel Hub Services'],
  [Account.TRIAL, TRIAL_ACCOUNT_TEXT],
  [Account.EXPLORATION, 'Exploration Account'],
  [Account.BASIC, 'Basic Account'],
  [Account.ENTERPRISE, 'Custom Enterprise Account'],
  [Account.ENTERPRISE_S, 'Enterprise S Account'],
  [Account.ENTERPRISE_L, 'Enterprise L Account'],
  [Account.FREE_ACCOUNT, 'Free Account'],
]);

export function getAccountTypeText(type: Account | undefined): string | undefined {
  return type === undefined ? undefined : accountTypeToTextMap.get(type);
}

export const isTrialUser = (type?: Account) => type === undefined || type === Account.TRIAL;
