import { CODE_DE } from '@src/meta/types/byoc/const';
import config from '@config/config';

export const BYOC_LOCATION_CONFIG: { [s: string]: string | undefined } = {
  [CODE_DE]: config.byocCodeDeServiceBasePath,
};

export const ALL_LOCATIONS_CONFIG: { [s: string]: string | undefined } = {
  ...BYOC_LOCATION_CONFIG,
};
