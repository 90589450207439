import { Table } from 'semantic-ui-react';
import { styled } from '@src/styles/Theme';
import { SpacedBetween } from '@src/components/common/SpacedStyle';

const BillingStyle = styled.section`
  padding: 0 16px;

  .billing-page__header {
    display: flex;
    justify-content: space-between;
  }

  .billing-page__header > a > button > i {
    margin-right: 10px;
  }

  .plans-content {
    display: flex;
    justify-content: space-between;
  }

  .list > .item {
    font-size: 0.92rem;
    margin-top: 0.5em;
  }
`;

export const SpacedBetweenResponsive = styled(SpacedBetween)`
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const StyledResponsiveTableContainer = styled(Table)`
  width: 40% !important;
  @media (max-width: 1000px) {
    width: 100% !important;
  }
`;
export default BillingStyle;
