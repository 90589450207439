import { isEqual, isNil, omitBy } from 'lodash';

import { CREODIAS_COLLECTIONS } from '@src/lib/skin/const';

import { LayerValues } from '../../../modules/Layer/LayerForm';
import { UserData } from '../../../services/WmsConfiguration/WmsInstanceUserDataReosurce';
import { DataProduct } from './DataProduct';
import {
  AcquisitionMode,
  Dataset,
  DatasetId,
  DemInstance,
  OrbitDirection,
  Polarization,
  Resolution,
  S3SlstrView,
  SPECKLE_FILTER_NONE_DEFAULT,
  SpeckleFilter,
  supportsAtmosphericCorrection,
  supportsClampNegative,
  supportsCloudCoverage,
  supportsEGM,
  supportsMosaicOrder,
  supportsPreviewMode,
  supportsTemporal,
  supportsTimeRange,
  supportsLandsatFiltering,
  supportsHarmonizeValues,
  LandsatFilters,
  supportsConstellation,
  HlsConstellation,
} from './Dataset';
import { AWS_US_WEST_2_COLLECTIONS } from '../../../lib/skin/producerCollections';

export type Layer = {
  id: string;
  title: string;
  defaultStyleName: string;
  description: string;
  orderHint: number;
  datasourceDefaults: LayerDatasourceDefaults;
  styles: Style[];
  userData?: UserData;
  additionalData?: WmsLayerAdditionalData;
  '@id'?: string;
};

export type HistoryStyle = {
  jn_created: Date;
  evalScript: string;
};

export type WmsLayerAdditionalData = {
  overlays: LayerOverlay[];
};

type LayerOverlay = {
  type: string;
};

export type BackCoeff = 'BETA0' | 'SIGMA0_ELLIPSOID' | 'GAMMA0_ELLIPSOID';

export enum ByocLayerSubtype {
  'BATCH' = 'BATCH',
  'BYOC' = 'BYOC',
  'ZARR' = 'ZARR',
}

export type LayerDatasourceDefaults = {
  maxCloudCoverage?: number;
  mosaickingOrder?: MosaickingOrder;
  type: DatasetId;
  previewMode?: PreviewMode;
  timeRange?: TimeRange;
  atmosphericCorrection?: AtmosphericCorrection;
  acquisitionMode?: AcquisitionMode;
  orbitDirection?: OrbitDirection;
  resolution?: Resolution;
  polarization?: Polarization;
  temporal?: boolean;
  upsampling?: Interpolator;
  downsampling?: Interpolator;
  orthorectify?: boolean;
  clampNegative?: boolean;
  EGM?: boolean;
  demInstance?: DemInstance;
  backCoeff?: BackCoeff;
  speckleFilter?: SpeckleFilter;
  view?: S3SlstrView;
  collectionId?: string;
  subType?: ByocLayerSubtype;
  tiers?: LandsatFilters;
  constellation?: HlsConstellation;
  harmonizeValues?: boolean;
  radiometricTerrainOversampling?: number;
};

export type Interpolator = 'BILINEAR' | 'BICUBIC' | 'LANCZOS' | 'BOX' | 'NEAREST';

export enum PreviewMode {
  PREVIEW = 'PREVIEW',
}

export enum AtmosphericCorrection {
  DOS1 = 'DOS1',
  SENCOR = 'SENCOR',
}

export enum MosaickingOrder {
  MOST_RECENT = 'mostRecent',
  LEAST_RECENT = 'leastRecent',
  LEAST_CC = 'leastCC',
}

export type TimeRange = {
  startTime: TimeSpec;
  endTime: TimeSpec;
};

export enum TimeRangeType {
  ABSOLUTE = 'ABSOLUTE',
  RELATIVE = 'RELATIVE',
}

export enum RelativeTimeUnit {
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  HOUR = 'HOUR',
}

export type TimeSpec = {
  type: TimeRangeType;
  value?: string;
  unit?: RelativeTimeUnit;
};

export type Style = {
  name: string;
  evalScript?: string;
  description: string;
  dataProduct?: DataProduct;
  legend?: Legend;
};

export type Legend = {
  type: string;
  title: string;
};

export function getLayerId(title: string) {
  return slugify(title).toUpperCase();
}

export function slugify(text: string) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/\([^()]*\)/g, '') // Remove all text between brackets ()
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export const isByocDataset = (layerDatasetId: DatasetId) => {
  return layerDatasetId === DatasetId.BYOC;
};

export const isUsWestDataset = (layerDatasetId: DatasetId) => {
  return AWS_US_WEST_2_COLLECTIONS.has(layerDatasetId);
};

export const isCreodiasDataset = (layerDatasetId: DatasetId) => {
  return CREODIAS_COLLECTIONS.has(layerDatasetId);
};

export const isUsWestDem = (demInstance: DemInstance | undefined) =>
  demInstance === undefined || demInstance === DemInstance.MAPZEN;

export const getDatasourceDefaults = (values: LayerValues, datasetId: DatasetId) => {
  const datasourceDefaults: LayerDatasourceDefaults = {
    type: datasetId,
  };

  datasourceDefaults.upsampling = values.upsampling
    ? (values.upsampling.toUpperCase() as Interpolator)
    : undefined;
  datasourceDefaults.downsampling = values.downsampling
    ? (values.downsampling.toUpperCase() as Interpolator)
    : undefined;

  if (supportsTemporal(datasetId)) {
    datasourceDefaults.temporal = values.temporal;
  }
  if (supportsMosaicOrder(datasetId)) {
    datasourceDefaults.mosaickingOrder = values.mosaicOrder || MosaickingOrder.MOST_RECENT;
  }
  if (supportsCloudCoverage(datasetId)) {
    datasourceDefaults.maxCloudCoverage = values.maxCloudCoverage;
  }
  if (supportsLandsatFiltering(datasetId)) {
    datasourceDefaults.tiers = values.tiers;
  }
  if (supportsTimeRange(datasetId) && values.timeRange !== undefined) {
    datasourceDefaults.timeRange = values.timeRange;
  }
  if (supportsAtmosphericCorrection(datasetId)) {
    datasourceDefaults.atmosphericCorrection = values.atmosphericCorrection;
  }
  if (supportsEGM(datasetId)) {
    datasourceDefaults.EGM = values.EGM;
  }
  if (supportsClampNegative(datasetId)) {
    datasourceDefaults.clampNegative = values.clampNegative;
  }
  if (supportsPreviewMode(datasetId)) {
    datasourceDefaults.previewMode = values.previewMode;
  }
  if (supportsHarmonizeValues(datasetId)) {
    datasourceDefaults.harmonizeValues = values.harmonizeValues;
  }
  if (supportsConstellation(datasetId) && values.constellation !== HlsConstellation.ANY) {
    datasourceDefaults.constellation = values.constellation;
  }
  if (datasetId === DatasetId.S1GRD) {
    datasourceDefaults.radiometricTerrainOversampling = values.radiometricTerrainOversampling;
  }

  if (
    (datasetId === DatasetId.S1GRD || datasetId === DatasetId.S3SLSTR) &&
    values.orbitDirection !== OrbitDirection.ANY
  ) {
    datasourceDefaults.orbitDirection = values.orbitDirection;
  }

  if (datasetId === DatasetId.S3SLSTR) {
    datasourceDefaults.view = values.view || S3SlstrView.NADIR;
  }

  if (datasetId === DatasetId.S1GRD) {
    datasourceDefaults.backCoeff = values.backCoeff;
    datasourceDefaults.speckleFilter = values.speckleFilter || SPECKLE_FILTER_NONE_DEFAULT;
    datasourceDefaults.orthorectify = values.orthorectify;
    datasourceDefaults.polarization = values.polarization;
    if (values.acquisitionMode !== AcquisitionMode.ANY) {
      datasourceDefaults.acquisitionMode = values.acquisitionMode;
    }
    if (values.resolution !== Resolution.ANY) {
      datasourceDefaults.resolution = values.resolution;
    }
    if (values.demInstance !== DemInstance.DEFAULT) {
      datasourceDefaults.demInstance = values.demInstance;
    }
  }

  if (datasetId === DatasetId.BYOC) {
    datasourceDefaults.collectionId = values.collectionId;
    datasourceDefaults.subType = values.subType;
  }

  if (datasetId === DatasetId.DEM && values.demInstance !== DemInstance.DEFAULT) {
    datasourceDefaults.demInstance = values.demInstance;
  }

  return omitBy(datasourceDefaults, isNil);
};

export const getDatasetSource = (datasets: Dataset[], datasetId: string) => {
  return datasets.find(ds => ds.id === datasetId)!.sources[0];
};

const toDefaultStyle = (style: Style) => {
  return {
    name: 'default',
    description: 'Default layer style',
    ...style,
  };
};

export const createNewLayerPayload = (
  values: LayerValues,
  datasets: Dataset[],
  instanceId: string,
) => {
  const { datasetId } = values;
  const datasetSource = getDatasetSource(datasets, datasetId);
  return {
    title: values.title,
    orderHint: values.orderHint,
    description: values.description,
    datasetSource,
    dataset: datasetSource.dataset,
    styles: (values.styles || []).map(toDefaultStyle),
    id: values.id,
    instanceId,
    defaultStyleName: 'default',
    datasourceDefaults: getDatasourceDefaults(values, datasetId),
    additionalData: values.additionalData,
  };
};

export const wasLayerModified = (layer: Layer, layerValues: LayerValues) => {
  const titleChanged = layer.title !== layerValues.title;
  const orderHintChanged = layer.orderHint !== layerValues.orderHint;
  const descriptionChanged = layer.description !== layerValues.description;
  const userDataChanged = !isEqual(layer.userData, layerValues.userData);
  const processingChanged = !isEqual(layer.styles, layerValues.styles);
  const additionalDataChanges = !isEqual(layer.additionalData, layerValues.additionalData);
  return (
    titleChanged ||
    orderHintChanged ||
    descriptionChanged ||
    userDataChanged ||
    processingChanged ||
    additionalDataChanges
  );
};

export const changeDatesetId = (layerStyle: Style, datasetId: string, setFieldValue: any) => {
  const newStyle = { ...layerStyle };
  delete newStyle.dataProduct;
  setFieldValue('styles', [newStyle]);
  setFieldValue('datasetId', datasetId);
};

export const updateDataProduct = (
  layerStyle: Style,
  dataProduct: DataProduct,
  setFieldValue: any,
) => {
  const newStyle = {
    ...layerStyle,
    dataProduct,
  };
  delete newStyle.evalScript;
  setFieldValue('styles', [newStyle]);
};

export const updateEvalscript = (layerStyle: Style, evalScript: string, setFieldValue: any) => {
  const newStyle = {
    ...layerStyle,
    evalScript,
  };
  delete newStyle.dataProduct;
  setFieldValue('styles', [newStyle]);
};
