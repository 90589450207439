import { List } from 'semantic-ui-react';

import config from '@config/config';

import { styled } from '../Theme';

export const COLLAPSED_SIDEBAR_WIDTH = '58px';
export const EXTENDED_SIDEBAR_WIDTH = '200px';
export const NAVBAR_HEIGHT = '65px';

type SidebarStyleProps = {
  sidebarCollapsed: boolean;
};

export const SidebarStyle = styled.nav<SidebarStyleProps>`
  position: fixed;
  z-index: 201;
  width: ${props => (props.sidebarCollapsed ? COLLAPSED_SIDEBAR_WIDTH : EXTENDED_SIDEBAR_WIDTH)};
  top: 0;
  bottom: 0;
  left: 0;
  margin-bottom: 0px;
  background: ${() => config.sidebarColor} !important;
  transition: width 0.3s, left 0.3s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .sidebar__bottom {
    border-top: 1px dashed white;
    width: 100%;
  }

  .sidebar-collapse-item {
    padding: 10px 0;
    margin-top: 5px;
    border-top: 1px dashed white;
  }

  .sidebar-collapse-item * > div.content,
  i.angle {
    color: ${() => config.primaryColor};
  }

  .sidebar-logo {
    padding: 10px 0;
    max-height: ${NAVBAR_HEIGHT};
    border-bottom: 1px dashed white;
    display: flex;
  }

  img.logo {
    max-height: ${NAVBAR_HEIGHT};
    padding: 15px 0;
  }

  .sidebar-logo:hover {
    cursor: pointer;
  }
`;

export const StyledSidebarItem = styled(List.Item)`
  background: ${props => (props.active ? config.selectedSidebarItemBackground : 'inherit')};
  padding: 0px !important;
  min-width: 100%;

  &:hover {
    background: #fff !important;
  }
`;
