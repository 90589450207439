import { PaypalEnv } from '@src/meta/types/billing/paypal';
import { AdyenEnv } from '@src/meta/types/billing/adyen';

import AppConfig, { CommonStylesConfig } from './baseAppConfig.types';

// Configuration Utility
const CONFIGURATIONS_UTILITY_PATH = '/configurations';
const NEW_CONFIGURATION_PATH = `${CONFIGURATIONS_UTILITY_PATH}/new`;
const CONFIGURATION_PATH = (instanceId: string) => `${CONFIGURATIONS_UTILITY_PATH}/${instanceId}`;
const NEW_CONFIGURATION_LAYER_PATH = (instanceId: string) =>
  `${CONFIGURATION_PATH(instanceId)}/new-layer`;

// Collections
const BYOC_PATH = '/collections';
const BYOC_PATH_FILTER = (filter: string) => `${BYOC_PATH}/${filter}?`;
const BYOC_COLLECTION_DETAILS_PATH = (collectionId: string) =>
  `${BYOC_PATH}/collection/${collectionId}/`;
const BYOC_COLLECTION_TILES_PATH = (collectionId: string) =>
  `${BYOC_PATH}/collection/${collectionId}/tiles`;
const BYOC_COLLECTION_REQUESTS_PATH = (collectionId: string) =>
  `${BYOC_PATH}/collection/${collectionId}/requests`;
const BYOC_COLLECTION_ORDERS_PATH = (collectionId: string) =>
  `${BYOC_PATH}/collection/${collectionId}/orders`;
const BYOC_COLLECTION_ZARR_ARRAY_PATH = (collectionId: string) =>
  `${BYOC_PATH}/collection/${collectionId}/arrays`;
const BYOC_COLLECTION_SUBSCRIPTIONS_PATH = (collectionId: string) =>
  `${BYOC_PATH}/collection/${collectionId}/subscriptions`;
const BYOC_NEW_COLLECTION_PATH = `${BYOC_PATH}/collection/new`;

const BYOC_OLD_PATH = '/byoc';
const BYOC_OLD_COLLECTIONS_DETAILS_PATH = (collectionId: string) =>
  `${BYOC_OLD_PATH}/collection/${collectionId}`;
const BYOC_OLD_NEW_COLLECTION_PATH = `${BYOC_OLD_PATH}/collections/new`;

// User Settings
const USER_SETTINGS_PATH = '/account/settings';

// News
const NEWS_PATH = `/news`;

// Help
const HELP_PAGE_PATH = '/help';

// Usage
const USAGE_PATH = '/statistics';

// Statistical Batch
const STATAPI_BATCH_PATH = '/statistical-batch';
const STATAPI_BATCH_DETAIL_PATH = (batchId: string) => `${STATAPI_BATCH_PATH}/${batchId}`;

// Batch
const BATCH_PATH = `/batch`;
const BATCH_DETAIL_PATH = (batchId: string) => `${BATCH_PATH}/${batchId}`;

// TPDI
const TPDI_PATH = `/tpdi`;
const TPDI_DETAIL_PATH = (orderId: string) => `${TPDI_PATH}/${orderId}`;
const SUBSCRIPTIONS_PATH = `${TPDI_PATH}/subscriptions`;
const SUBSCRIPTION_DETAIL_PATH = (subscriptionId: string) =>
  `${SUBSCRIPTIONS_PATH}/${subscriptionId}`;

// Billing
const BILLING_PATH = '/account/billing';
const USER_BILLING_INFO_PATH = (id: string | number) => `${BILLING_PATH}/user_info/${id}`;
const ORDER_HISTORY = `${BILLING_PATH}/orders/history`;
const ORDER_DETAILS = (orderId: string) => `${BILLING_PATH}/order/${orderId}`;
const CHECKOUT_PATH = `${BILLING_PATH}/checkout`;
const WIRE_TRANSFER_PAYMENT_PATH = `${BILLING_PATH}/payment/wire_transfer`;
const PAYPAL_EXECUTED_PATH = `${BILLING_PATH}/paypal/executed`;
const ADYEN_CHECKOUT_CALLBACK_PATH = `${BILLING_PATH}/adyen-checkout/callback`;

export enum Environment {
  LOCAL = 'LOCAL',
  TEST = 'TEST',
  STAGE = 'STAGE',
  PRODUCTION = 'PRODUCTION',
}

const stylesConfig: CommonStylesConfig = {
  primaryTextColor: '',
  secondaryTextColor: '',
  terciaryTextColor: '',
  quaternaryTextColor: '',

  backgroundColor: '',
  lightBackgroundColor: '',
  darkBackgroundColor: '',

  backgroundGrey: '',
  lightBackgroundGrey: '',
  darkBackgroundGrey: '',

  primaryColor: '',
  secondaryColor: '',
  terciaryColor: '',

  sidebarColor: '',
  selectedSidebarItemBackground: '',

  iconColor: 'black',

  logo: '',
  collapsedLogo: '',

  toastSuccessColor: '',
  toastErrorColor: '',
};

const config: AppConfig = {
  configurationUtilityEnabled: true,
  collectionsEnabled: true,
  userSettingsEnabled: true,
  newsEnabled: true,
  helpEnabled: true,
  usageEnabled: true,
  statisticalBatchEnabled: true,
  fisEnabled: true,
  batchEnabled: true,
  tpdiEnabled: true,
  billingEnabled: true,
  billingHistoryEnabled: true,
  statisticalApiEnabled: true,
  asyncProcessingApiEnabled: true,
  zarrApiEnabled: true,
  CONFIGURATIONS_UTILITY_PATH,
  CONFIGURATION_PATH,
  NEW_CONFIGURATION_PATH,
  NEW_CONFIGURATION_LAYER_PATH,
  BYOC_PATH,
  BYOC_PATH_FILTER,
  BYOC_COLLECTION_DETAILS_PATH,
  BYOC_COLLECTION_TILES_PATH,
  BYOC_COLLECTION_REQUESTS_PATH,
  BYOC_COLLECTION_ORDERS_PATH,
  BYOC_COLLECTION_ZARR_ARRAY_PATH,
  BYOC_COLLECTION_SUBSCRIPTIONS_PATH,
  BYOC_NEW_COLLECTION_PATH,
  BYOC_OLD_PATH,
  BYOC_OLD_COLLECTIONS_DETAILS_PATH,
  BYOC_OLD_NEW_COLLECTION_PATH,
  USER_SETTINGS_PATH,
  NEWS_PATH,
  HELP_PAGE_PATH,
  USAGE_PATH,
  STATAPI_BATCH_PATH,
  STATAPI_BATCH_DETAIL_PATH,
  BATCH_PATH,
  BATCH_DETAIL_PATH,
  TPDI_PATH,
  TPDI_DETAIL_PATH,
  SUBSCRIPTIONS_PATH,
  SUBSCRIPTION_DETAIL_PATH,
  BILLING_PATH,
  USER_BILLING_INFO_PATH,
  ORDER_HISTORY,
  ORDER_DETAILS,
  CHECKOUT_PATH,
  WIRE_TRANSFER_PAYMENT_PATH,
  PAYPAL_EXECUTED_PATH,
  ADYEN_CHECKOUT_CALLBACK_PATH,
  ...stylesConfig,

  // Placeholders
  clientId: '',
  adyenClientId: '',
  keycloakAuthUrl: '',
  keycloakRealm: '',
  accountClaimsKey: '',
  userClaimsKey: '',
  appsBaseUrl: '',
  configurationTemplateAccountId: '',

  wmsConfigurationServiceBasePath: '',
  identityServiceBasePath: '',
  rateLimitSupervisorServiceBasePath: '',
  dashboardServiceBasePath: '',
  accountingServiceBasePath: '',
  accessControlServiceBasePath: '',
  managementServiceBasePath: '',

  byocGlobalServiceBasePath: '',
  byocEuCentralServiceBasePath: '',
  byocUsWestServiceBasePath: '',
  byocSgsHqServiceBasePath: '',
  byocCreodiasServiceBasePath: '',
  byocCodeDeServiceBasePath: '',
  tpdiServiceBasePath: '',

  defaultWmsInstanceBaseUrl: '',
  ogcServiceBasePath: '',
  usWestWmsServiceBasePath: '',
  creodiasWmsServiceBasePath: '',

  sentry: {
    dsn: '',
    env: '',
    enable: false,
  },

  analytics: {
    trackingID: '',
    enable: false,
  },

  paypalEnv: PaypalEnv.SANDBOX,
  adyenEnvironment: AdyenEnv.TEST,
  environment: Environment.LOCAL,

  displayTopPageBanners: false,
  helpLinks: [],
  contactLink: '',
  showPromoCodeForm: false,
  showMarketingPermissions: false,
  showValidTo: false,
  defaultByocLocation: '',
  getSupportedDeployedCollections: () => new Set(),
  logoutUrl: '',
  oAuthClientsExpiryEnabled: false,
};

export default config;
