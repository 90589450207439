import { ServerDomainAccount } from '@modules/auth/components/types/user';
import config from '@config/config';

import api from '../../../lib/api';
import { CollectionWrapper, ResourceFetch } from '../../../meta/types/api';

const { GET } = api;

const ACCOUNT_RESOURCE_BASE_PATH = `${config.identityServiceBasePath}/users/:userId/accounts`;

export interface AccountResource {
  list: ResourceFetch<CollectionWrapper<ServerDomainAccount>, { userId: string }>;
}

const AccountResource: AccountResource = {
  list: GET(`${ACCOUNT_RESOURCE_BASE_PATH}`),
};

export default AccountResource;
