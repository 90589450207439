import { styled } from '../../../styles/Theme';

export const SpacedBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SpacedEvenly = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
