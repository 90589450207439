export const TPDI_AWS_EU_CENTRAL_1 = 'sh.tpdi.byoc.eu-central-1';
export const TPDI_AWS_US_WEST_2 = 'sh-tpdi-byoc-us-west-2';
export const TPDI_GCP_US_CENTRAL_1 = 'sh-tpdi-byoc-uscentral1';
export const AWS_EU_CENTRAL_1 = 'aws-eu-central-1';
export const AWS_US_WEST_2 = 'aws-us-west-2';
export const SGS_HQ = 'sgs-hq';
export const CREODIAS = 'creo';
export const CODE_DE = 'codede';
export const EO_LAB = 'eolab';
export const CDSE = 'cdse';
