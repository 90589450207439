import { Account } from '@modules/auth/components/types/account';
import { ComplianceStatus } from '@src/meta/types/compliance';

type Accounts = {
  [index: number]: {
    type: number;
    name: string;
    validity: string;
  };
};
export type SentinelUser = {
  created: Date;
  givenName: string;
  familyName: string;
  email: string;
  countryCode?: string;
  id: string;
  organization: Organization;
  active: boolean;
  extra?: SentinelUserExtra;
  complianceStatus?: ComplianceStatus;
};

export type DomainAccountWithDomain = {
  created: Date;
  data: DomainAccountData;
  id: string;
  userId: string;
  validTo: Date;
  domain: Domain;
  type: Account;
  domainId: number;
};

export type DomainData = {
  defaultAccountId: number;
  accounts: Accounts;
  termsUrl: string;
};

export type Domain = {
  id: number;
  name: string;
  description?: string;
  data: DomainData;
};

export type DomainAccount = {
  created: Date;
  data: DomainAccountData;
  id: string;
  userId: string;
  domainId: number;
  validTo: Date;
  type: Account;
};

export enum RoleAssignment {
  CONFIGURATION = 'cfg',
  INDEX = 'idx',
  DASHBOARD = 'dbd',
  IDENTITY = 'idn',
  MANAGEMENT = 'idn',
  OGC = 'ogc',
  PROCESSOR = 'prc',
  ASYNC_PROCESSOR = 'asp',
  BYOC = 'byc',
  DATA_IMPORT = 'di',
  RATE_LIMIT = 'rl',
  BATCH = 'btc',
  BATCH_STATISTICS = 'btc',
  ZARR = 'byc',
  ACCOUNTING = 'acc',
  ACCESS_CONTROL = 'acl',
  RATE_OVERLORD = 'rol',
  INDEX_SERVICE2 = 'idx2',
}

export enum Role {
  NO_ROLE = 0,
  USER = 1,
  ROOT = 2,
  SH_SERVICE = 3,
  APPLICATION_RO = 4,
}

type RoleAssignmentMap = {
  [key in RoleAssignment]: Role;
};
export type RoleAssignmentGroup = {
  id: number;
  name: string;
  assignments: RoleAssignmentMap;
};

export type Organization = {};

export type RolesAssignment = {
  rag: number;
};

export type Claims = {
  name: string;
  given_name?: string;
  family_name?: string;
  email: string;
  preferred_username: string;
} & { [key: string]: string };

export type DomainAccountData = {
  expiry_status: string;
  followup_status: string;
  rolesAssignment: RolesAssignment;
  oauthClientLimitN?: number;
};

type SentinelUserExtra = {
  extAuthProviderId?: number;
  extAuthId?: string;
};

/* Server responses */

export const toInternalUser = (user: ServerSentinelUser): SentinelUser => {
  return { ...user, created: new Date(user.created) };
};

export type ServerDomainAccount = {
  created: string;
  data: DomainAccountData;
  id: string;
  domainId: number;
  userId: string;
  validTo: string;
  type: number;
};

export type ServerOrganization = {
  '@id': string;
};

export type ServerSentinelUser = {
  id: string;
  givenName: string;
  familyName: string;
  email: string;
  created: string;
  active: boolean;
  organization: ServerOrganization;
  extra: SentinelUserExtra;
};

export interface UserIdentifiable {
  userId: string;
}
