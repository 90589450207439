import React, { createContext } from 'react';
import { connect } from 'react-redux';

import { StoreState } from '@src/meta/types/store';
import { RoleAssignmentGroup, Role } from '@modules/auth/components/types/user';
import { getRoleAssignmentGroup } from '@modules/auth/components/lib/account';
import { alertInfo } from '@src/lib/alert';

type ConfigurationsContextProps = {
  readonly: boolean;
};

export const ConfigurationsContext = createContext<ConfigurationsContextProps>({
  readonly: false,
});

type Props = {
  children: React.ReactNode;
};

type PropsFromStore = {
  roleAssignments: RoleAssignmentGroup | undefined;
  isImpersonating: boolean;
};

function hasReadonlyAccess(
  roleAssignmentGroup: RoleAssignmentGroup | undefined,
  isImpersonating: boolean,
) {
  const readonly = Boolean(
    roleAssignmentGroup && roleAssignmentGroup.assignments.cfg === Role.APPLICATION_RO,
  );
  if (readonly && isImpersonating) {
    alertInfo(
      'Configuration Utility is READONLY for the impersonated account but editable for managing!',
    );
    return false;
  }
  return readonly;
}

function ConfigurationsContextProvider({
  children,
  roleAssignments,
  isImpersonating,
}: Props & PropsFromStore) {
  return (
    <ConfigurationsContext.Provider
      value={{
        readonly: hasReadonlyAccess(roleAssignments, isImpersonating),
      }}
    >
      {children}
    </ConfigurationsContext.Provider>
  );
}

const mapStateToProps = (state: StoreState): PropsFromStore => {
  return {
    roleAssignments: getRoleAssignmentGroup(state),
    isImpersonating: state.auth.rootManaging,
  };
};

export default connect(mapStateToProps)(ConfigurationsContextProvider);
