import produce, { Draft } from 'immer';
import { DomainAccountWithDomain } from '@modules/auth/components/types/user';
import { RoleAssignmentGroup } from '@modules/auth/components/types/user/index';

import { ISetAccounts, ISetAccountsFetching, ISetAssignmentGroups } from '../../actions/accounts';
import * as actionTypes from '../../constants/actionTypes';

export const initialState = Object.freeze({
  accounts: [] as DomainAccountWithDomain[],
  fetched: false,
  fetching: false,
  rags: [] as RoleAssignmentGroup[],
});

export type AccountState = typeof initialState;

const account = (
  state: AccountState = initialState,
  action: ISetAccounts | ISetAccountsFetching | ISetAssignmentGroups,
) => {
  return produce<AccountState>(state, (draft: Draft<AccountState>) => {
    switch (action.type) {
      case actionTypes.SET_ACCOUNTS:
        draft.fetched = true;
        draft.fetching = false;
        draft.accounts = action.accounts;
        return;
      case actionTypes.SET_ACCOUNTS_FETCHING:
        draft.fetching = true;
        return;
      case actionTypes.SET_ROLE_ASSIGNMENT_GROUPS:
        draft.rags = action.rags;
        return;
      default:
        return;
    }
  });
};

export default account;
