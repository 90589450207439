import React from 'react';
import { PoseGroup } from 'react-pose';

import { FunctionComponentProps, RouteContainer } from '@src/config/default/modules/Billing';
import { BaseAuthRoute } from '@src/components/common/Route';
import config from '@config/config';

import BillingPage from './BillingPage';

function Billing(props: FunctionComponentProps) {
  return (
    <PoseGroup className="billing-routes-container">
      <RouteContainer key={location.pathname}>
        <BaseAuthRoute path={config.BILLING_PATH} component={BillingPage} />
      </RouteContainer>
    </PoseGroup>
  );
}

export default Billing;
