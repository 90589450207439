import React from 'react';
import { DomainAccount } from '@modules/auth/components/types/user';
import { Account } from '@modules/auth/components/types/account';

import PrimaryButton from '@src/components/common/buttons/PrimaryButton';
import { Link } from 'react-router-dom';
import config from '@config/config';

export type Props = {
  rootManaging: boolean;
  account?: DomainAccount;
};

const UNUPGRADABLE_ACCOUNTS = [Account.ROOT, Account.ENTERPRISE];

const UpgradeAccountButton = ({ rootManaging, account }: Props) => {
  const isUpgradable = account
    ? UNUPGRADABLE_ACCOUNTS.find(a => a === account.type) === undefined
    : true;

  if (!rootManaging && isUpgradable) {
    return (
      <li>
        <PrimaryButton content="Upgrade" as={Link} to={config.BILLING_PATH} />
      </li>
    );
  }

  return null;
};

export default UpgradeAccountButton;
