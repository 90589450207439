import { CODE_DE } from '@src/meta/types/byoc/const';
import { CODEDE_COLLECTIONS } from '@src/lib/skin/const';

import baseAppConfig from '../baseAppConfig';
import stylesConfig from './stylesConfig';
import AppConfig from '../baseAppConfig.types';

const CODE_DE_HELP_LINK = 'https://code-de.org/help';

const CODEDE_HELP_LINKS = [
  { text: 'CODE-DE Help', link: CODE_DE_HELP_LINK },
  { text: 'Documentation', link: 'https://docs.sentinel-hub.com' },
  { text: 'Forum', link: 'https://forum.sentinel-hub.com' },
  { text: 'FAQ', link: 'https://www.sentinel-hub.com/faq/' },
];

const config: AppConfig = {
  ...baseAppConfig,
  ...stylesConfig,
  batchEnabled: false,
  statisticalBatchEnabled: false,
  tpdiEnabled: false,
  newsEnabled: false,
  usageEnabled: false,
  billingHistoryEnabled: false,

  displayTopPageBanners: false,
  helpLinks: CODEDE_HELP_LINKS,
  contactLink: CODE_DE_HELP_LINK,
  showMarketingPermissions: true,
  showValidTo: false,
  defaultByocLocation: CODE_DE,
  getSupportedDeployedCollections: () => CODEDE_COLLECTIONS,
};
export default config;
