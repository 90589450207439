import 'react-toastify/dist/ReactToastify.css';

import * as Sentry from 'raven-js';
import * as Fathom from 'fathom-client';
import React from 'react';
import { Route, HashRouter as Router } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import withAuthorization, { AuthorizationProps } from '@modules/auth';
import config from '@config/config';
import Routes from '../../Routes';
import GlobalStyle from '../../styles/global';
import { Theme } from '../../styles/Theme';
import getTheme from '../../styles/Theme/theme';
import { VERSION_INFO } from '../../version';

type State = {
  theme: Theme;
};

class App extends React.Component<AuthorizationProps, State> {
  constructor(props: AuthorizationProps) {
    super(props);
    if (config.sentry.enable) {
      this.installSentry();
    }
    this.state = {
      theme: getTheme(),
    };
  }

  componentDidMount() {
    Sentry.captureBreadcrumb({
      level: 'info',
      category: 'ui',
      message: `App mounted on ${window.location.href}`,
      data: VERSION_INFO,
    });
    if (config.analytics.enable) {
      Fathom.load(config.analytics.trackingID, {
        url: 'https://water-knowing.sentinel-hub.com/script.js',
        spa: 'auto',
      });
    }
  }

  installSentry = () => {
    Sentry.config(config.sentry.dsn, {
      environment: config.sentry.env,
      release: VERSION_INFO.tag,
    }).install();
  };

  render() {
    return (
      <Router>
        <Route
          render={({ location }) => {
            return (
              <ThemeProvider theme={this.state.theme}>
                <>
                  <GlobalStyle />
                  <Routes location={location} />
                  <ToastContainer autoClose={3000} pauseOnHover />
                </>
              </ThemeProvider>
            );
          }}
        />
      </Router>
    );
  }
}

const AuthorizedApp = withAuthorization(App);

export default AuthorizedApp;
