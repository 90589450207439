import config from '@config/config';

import api from '../../../lib/api';
import { CollectionWrapper, ResourceFetch } from '../../../meta/types/api';
import { CountryDto } from '../../../meta/types/billing/vat';

const { GET } = api;

export interface CountryResource {
  list: ResourceFetch<CollectionWrapper<CountryDto>>;
}

const CountryResource: CountryResource = {
  list: GET(`${config.identityServiceBasePath}/countries`),
};

export default CountryResource;
